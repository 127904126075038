<template>
  <div>
    <!--begin::Advance Table Widget 9-->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            API Credentials
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm"></span>
        </h3>
        <div class="card-toolbar">
          <a
            href="javascript:"
            class="btn btn-success font-size-sm text-uppercase"
            :disabled="isBusy"
            @click="generateKey()"
          >
            <i class="flaticon2-add-1"></i> Generate new key
          </a>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <v-alert type="info" v-if="apiKey === null">
          <p>To get your API key, click the button above.</p>
          <p><b>Warning! After the API key is generated, all previous keys will be unavailable and you will not be able to make requests with them.</b></p>
        </v-alert>
        <v-alert type="success" v-if="apiKey !== null">
          <p>Your new API key is:</p>
          <p><b>{{ apiKey }}</b></p>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { GENERATE_KEY } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ApiCredentials",
  data() {
    return {
      apiKey: null,
      isBusy: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "API Credentials" }]);
  },
  methods: {
    generateKey() {
      if (this.isBusy) {
        return null;
      }

      this.isBusy = true;
      this.$store.dispatch(GENERATE_KEY).then(() => this.isBusy = false);
    }
  },
  computed: {
    ...mapGetters(["key"])
  },
  watch: {
    key(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.apiKey = newValue;
      }
    }
  }
};
</script>

<style scoped></style>
